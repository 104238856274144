import React, { useEffect, useState } from 'react';
import { Radio, RadioGroup, FormControlLabel, TextField } from '@material-ui/core';
import ShippingBeerLoads from './ShippingBeerLoads';

function ShippingMethod({customerId, needByValChanged, selectedShippingMethod, onSelectedShippingMethod, handleDisabledNext, onSelectedBeerLoad}) {
  const [selectedOption, setSelectedOption] = useState(
    selectedShippingMethod === 'beerLoad' ? 'beerLoad' : 'fedex'
  );

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    console.log('Selected shipping method:', event.target.value);
    if (event.target.value === 'beerLoad') {
      needByValChanged('');
      handleDisabledNext();
      setSelectedOption('beerLoad');
      onSelectedShippingMethod('beerLoad');
    } else {
      needByValChanged('');
      handleDisabledNext();
      setSelectedOption('fedex');
      onSelectedShippingMethod('fedex');
    }
  };

  const handleNeedByValChange = (event) => {
    // Handle the need-by date change
    console.log('Need-by date:', event.target.value);
    needByValChanged(event.target.value);
  };

  useEffect(() => {
    console.log('ShippingMethod/selectedShippingMethod: ', selectedShippingMethod);
    
  }, []);

  return (
    <div>
      <RadioGroup value={selectedShippingMethod} onChange={handleOptionChange}>
        <FormControlLabel value="beerLoad" control={<Radio />} label="Beer Load" />
        {selectedShippingMethod === 'beerLoad' && (
        <div>
          <ShippingBeerLoads customerId={customerId} onSelectedBeerLoad={onSelectedBeerLoad} />
          {/* <div style={{borderRadius: '10px', border: '1px solid black', padding: 'none'}}>
            
          </div> */}
        </div>
      )}
        <FormControlLabel value="fedex" control={<Radio />} label="FedEx" />
      </RadioGroup>

     

      {selectedShippingMethod !== 'beerLoad' && (
        <div>
          <TextField
            type="date"
            id="need-by"
            name="need-by"
            defaultValue={selectedShippingMethod === 'beerLoad' ? '' : selectedShippingMethod}
            label="Need By Date"
            variant="outlined"
            style={{ marginTop: '32px' }}
            onChange={handleNeedByValChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      )}
    </div>
  );
}

export default ShippingMethod;