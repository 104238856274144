import React, { createContext, useState } from 'react';

export const OriginalItemsContext = createContext();

export const OriginalItemsProvider = ({ children }) => {
  const [originalItems, setOriginalItems] = useState([]);

  return (
    <OriginalItemsContext.Provider value={[originalItems, setOriginalItems]}>
      {children}
    </OriginalItemsContext.Provider>
  );
}