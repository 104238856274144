import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    //position: 'absolute',
    top: '85px',
    
  },
});

export default function BrandNavBar({onChange}) {
  const classes = useStyles();
  const [value, setValue] = React.useState('BOULEVARD');

  const handleChange = (event, newValue) => {
    const selectedTabLabel = event.target.innerText;
    setValue(selectedTabLabel);
    //setValue(newValue);
    onChange(newValue);
  };

  return (
    <Paper className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="Boulevard" value="BOULEVARD" />
        <Tab label="Quirk" value="QUIRK" />
        <Tab label="Ommegang" value="OMMEGANG" />
        <Tab label="Duvel" value="DUVEL" />
        <Tab label="Chouffe" value="CHOUFFE" />
        
      </Tabs>
    </Paper>
  );
}
