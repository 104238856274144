import React from 'react';
import { useMsal } from "@azure/msal-react";
import Button from '@material-ui/core/Button';
import { styled } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';

const StyledButton = styled(Button)({
  background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
  border: 'none',
  color: 'white',
  height: 60,
  width: 200,
  fontSize: '1.2rem',
  borderRadius: 30,
  textTransform: 'uppercase',
  letterSpacing: '2px',
  fontWeight: 'bold',
  boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-3px)',
    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.3)',
  },
});

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  background: '#252525',
});

const Title = styled(Typography)({
  color: 'white',
  fontSize: '2.5rem',
  fontWeight: 'bold',
  marginBottom: '2rem',
  textTransform: 'uppercase',
  letterSpacing: '4px',
});

export default function LoginButton(props) {
  const { instance } = useMsal();

  const handleLogin = async () => {
    try {
      const response = await instance.loginPopup();
      const account = response.account;
      const username = account.username;
      console.log("Logged in as: ", username);
      props.onLogin(username);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Container>
      <div style={{ position: 'absolute', bottom: '0', left: '2px', color: 'gold', fontSize: '10px' }}>version: {localStorage.getItem("appVersion")}</div>
      <Title variant="h4">Login - DUSA POS System</Title>
      <StyledButton onClick={handleLogin}>
        Login
      </StyledButton>
    </Container>
  );
}