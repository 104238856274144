import React, { useEffect, useState, useRef } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@material-ui/core';
import Admin_Item from './Admin_Item';

const Admin_ItemsList = ({ originalItems, refreshItems }) => {
  const [items, setItems] = useState(originalItems);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [newPrice, setNewPrice] = useState('');

  useEffect(() => {
    setItems(originalItems);
  }, [originalItems]);

  
  const [editingItemNo, setEditingItemNo] = useState(null);
  const [editingPriceItemNo, setEditingPriceItemNo] = useState(null);

  // const handleEditingPrice = (item) => {
  //   //setSelectedItem(item);
  //   setNewPrice(item.price || '');
  //   setOpenDialog(true);
  //   setEditingPriceItemNo(item.itemNo_);
  // };

  const handleStopEditingPrice = () => {
    setOpenDialog(false);
    //setSelectedItem(null);
    setNewPrice('');
    setEditingPriceItemNo(null);
  };

  const handlePriceChange = (e) => {
    setNewPrice(e.target.value);
  };

  // const handleSavePrice = () => {
  //   const updatedItems = items.map((item) => {
  //     if (item.itemNo_ === selectedItem.itemNo_) {
  //       return { ...item, price: newPrice };
  //     }
  //     return item;
  //   });
  //   setItems(updatedItems);
  //   handleCloseDialog();
  // };

  const priceInputRef = useRef(null);

  const handleSavePrice = (itemNo_, newPrice) => {
    //const item = items.find((item) => item.itemNo_ === itemNo_);
    const requestBody = {
      itemNo: itemNo_,
      price: priceInputRef.current.value,
    };
    //alert(requestBody);
    fetch('https://posapi.duvelusa.com/Orders/UpdateItemPrice', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Error updating item price');
        }
        setEditingPriceItemNo(null);
        refreshItems();
      })
      .catch((error) => {
        console.error('Error updating item price:', error);
      });
  };

  const fileInputRefs = useRef({});

  const handleImageUpload = (event, itemNo_) => {
    //alert('1')
    event.preventDefault();
    fileInputRefs.current[itemNo_].click();
  };

  const handleFileInputChange = (event, itemNo_) => {
    //alert('2')
    const imageFile = event.target.files[0];
    console.log(imageFile);
    const formData = new FormData();
    formData.append("imageFile", imageFile);

    fetch(`https://posapi.duvelusa.com/Orders/UpdateItemImage?itemNo_=${itemNo_}`, {
      method: "POST",
      body: formData
    })
      .then(response => {
        if (!response.ok) {
          throw new Error("Error uploading image");
        }
        //alert('3')
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
          return response.json();
        } else {
          return response.text();
        }
      })
      .then(data => {
        console.log("Image uploaded successfully:", data.text);
        refreshItems();
        // Perform any necessary actions after successful upload
      })
      .catch(error => {
        console.error("Error uploading image:", error);
        // Handle the error scenario
      });
  };


  const handleDescriptionChange = (e, itemNo_) => {
    const updatedItems = items.map((item) => {
      if (item.itemNo_ === itemNo_) {
        return { ...item, description: e.target.value };
      }
      return item;
    });
    setItems(updatedItems);
  };
  
  const handleDescriptionKeyDown = (e, itemNo_) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const item = items.find((item) => item.itemNo_ === itemNo_);
      const requestBody = {
        itemNo: itemNo_,
        customItemName: item.description,
      };
      fetch('https://posapi.duvelusa.com/Orders/UpdateCustomItemName', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Error updating custom item name');
          }
          setEditingItemNo(null);
          refreshItems();
        })
        .catch((error) => {
          console.error('Error updating custom item name:', error);
        });
    }
  };

  const handleHideClick = (e, itemNo_, hidden) => {
    const requestBody = {
      ItemNo_: itemNo_,
      Hidden: hidden,
    };
    fetch('https://posapi.duvelusa.com/Orders/InsertHiddenItem', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Error hiding/unhiding the item');
        }
        refreshItems();
      })
      .catch((error) => {
        console.error('Error hiding/unhiding the item:', error);
      });
  };
  

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th align="left" width="100px">Item No.</th>
            <th align="left" width="80px">Hide</th>
            <th align="left" width="365px">Description</th>
            <th align="left" width="128px">Sale UOM</th>
            <th align="left" width="80px">Price</th>
            <th align="left">Image</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => (
            <tr key={index}>
              <td align="left">{item.itemNo_}</td>
              
              <td>
                <input type="checkbox" checked={item.blocked}
                  onClick={(e) => handleHideClick(e, item.itemNo_, !item.blocked)}></input>
              </td>
              {/* <td align="left">{item.description}</td> */}

              {/* DESCRIPTION */}
              <td align="left">
                {editingItemNo === item.itemNo_ ? (
                  <input type="text" style={{width: '300px'}}
                         value={item.description}
                         onChange={(e) => handleDescriptionChange(e, item.itemNo_)}
                         onKeyDown={(e) => handleDescriptionKeyDown(e, item.itemNo_)}
                         onBlur={() => setEditingItemNo(null)}
                         autoFocus
                  />
                ) : (
                  <span style={{ color: 'inherit', 
                                 cursor: 'pointer',
                                 textDecoration: 'none' }}
                        onMouseEnter={(e) => e.target.style.textDecoration = 'underline'}
                        onMouseLeave={(e) => e.target.style.textDecoration = 'none'}
                        onClick={() => setEditingItemNo(item.itemNo_)}
                        >{item.description}
                  </span>
                )}
              </td>
              
              {/* UOM */}
              <td align="left">{item.uom}</td>

              {/* PRICE */}
              <td align="left">
                
                {/* <a href="#"
                   onClick={() => handleEditingPrice(item)}
                   style={{ color: item.price ? 'inherit' : 'red' }}>${item.price || '0'}
                </a> */}

              {editingPriceItemNo === item.itemNo_ ? (
                  <div>
                    <input
                      type="number"
                      id="priceUpdateTxt"
                      ref={priceInputRef}
                      //value={item.price || ''}
                      onChange={(e) => handlePriceChange(e, item.itemNo_)}
                      //onKeyDown={(e) => handlePriceKeyDown(e, item.itemNo_)}
                      style={{ width: '80px' }}
                      autoFocus
                    />
                    <span
                      style={{ marginLeft: '8px', textDecoration: 'underline', cursor: 'pointer' }}
                      onClick={() => handleSavePrice(item.itemNo_)}
                    >
                      Save
                    </span>
                    <span
                      style={{ marginLeft: '8px', textDecoration: 'underline', cursor: 'pointer' }}
                      onClick={() => setEditingPriceItemNo(null)}
                    >
                      Cancel
                    </span>
                  </div>
                ) : (
                  
                  <a href="#"
                    onClick={() => setEditingPriceItemNo(item.itemNo_)}
                    style={{ color: item.price ? 'inherit' : 'red' }}
                  >
                    ${item.price || '0'}
                  </a>
                )}

              </td>

              {/* IMAGE */}
              <td>
                {/* imagePath */}

                {item.imagePath ? (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span>{item.imagePath}</span>
                    <a href="#" onClick={(event) => handleImageUpload(event, item.itemNo_)} style={{ marginLeft: '10px' }}>
                      (Change)
                    </a>
                  </div>
                ) : (
                  <>
                    <>No Image - </>
                    <a href="#" onClick={(event) => handleImageUpload(event, item.itemNo_)}>
                      (Upload)
                    </a>
                  </>
                )}
                <input
                  type="file"
                  accept=".jpg,.png"
                  style={{ display: "none" }}
                  onChange={(event) => handleFileInputChange(event, item.itemNo_)}
                  ref={(el) => (fileInputRefs.current[item.itemNo_] = el)}
                />

                {/* {item.imagePath ? item.imagePath : 
                  <>
                    <>No Image - </>
                    <a href="#" onClick={(event) => handleImageUpload(event, item.itemNo_)}>
                      (Upload)
                    </a>
                    <input
                      type="file"
                      accept=".jpg,.png"
                      style={{ display: "none" }}
                      onChange={(event) => handleFileInputChange(event, item.itemNo_)}
                      ref={(el) => (fileInputRefs.current[item.itemNo_] = el)}
                    />
                  </>
                } */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
{/* 
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Modify Price</DialogTitle>
        <DialogContent>
          <TextField
            label="Price"
            value={newPrice}
            onChange={handlePriceChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSavePrice} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog> */}
    </div>
  );
};

export default Admin_ItemsList;