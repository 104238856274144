import React from 'react';
import { Dialog, DialogContent, Typography, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    backgroundColor: theme.palette.grey[900],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(4),
  },
  typography: {
    color: theme.palette.common.white,
    marginBottom: theme.spacing(2),
  },
}));

const SubmittingOrderDialog = ({ open }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="h5" className={classes.typography}>
          Submitting Order Please Wait...
        </Typography>
        <LinearProgress color="secondary" style={{ width: '100%' }} />
      </DialogContent>
    </Dialog>
  );
};

export default SubmittingOrderDialog;