import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dialog: {
    minWidth: '400px',
  },
  dialogContent: {
    textAlign: 'center',
    padding: theme.spacing(3),
  },
  successText: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
}));

const SuccessDialog = ({ open, onClose }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} className={classes.dialog}>
      <DialogTitle>Order Submitted</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="h6" className={classes.successText}>
          Success!
        </Typography>
        <Typography>
          Your order has been submitted. Please look for the confirmation email.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SuccessDialog;