import React, { Fragment, useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogTitle, DialogContent, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import Cart from '../../../imgs/cart.jpg';
import ReviewOrder from './ShoppingCart_ReviewOrder';
import ReviewStepper from './ShoppingCart_ReviewStepper';


// Define custom styles for the Box components
const useStyles = makeStyles({
  header: {
    backgroundColor: 'white',
    border: '1px solid grey',
    borderRadius: '10px',
    height: '64px',
    overflowY: 'auto',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 'bold',
    color: '#5D4037',
    fontSize: '24px',
    padding: '2px'
    //lineHeight: '2px',
  },
  header_weird: {
    backgroundColor: '#FBD7C7',
    fontFamily: "'Roboto', sans-serif",
    fontWeight: 'bold',
    color: '#5D4037',
    fontSize: '24px',
    textAlign: 'center',
    lineHeight: '32px',
    height: '32px',
  },
  cart: {
    // boxShadow: 'inset 0 0 12px 4px rgba(0,0,0,0.1)',
    // backgroundColor: 'white',
    border: '1px solid purple',
    borderRadius: '4px',
    minHeight: '512px',
    maxHeight: '512px',
    overflowY: 'auto',
    overscrollBehaviorY: 'contain'
  },
  cartIcons: {
    fontSize: '14px',
    backgroundColor: 'transparent',
    '&:hover': {
      background: 'none',
      backgroundColor: 'transparent'
    },
  },
  item: {
    //backgroundColor: 'tan',
    //border: '1px solid grey',
    borderRadius: '10px',
    width: '80%',
    height: '80%',
    margin: 'auto',
    // boxShadow: 'inset 0 0 5px rgba(0,0,0,0.2)',
    marginBottom: '2px',
    // backgroundColor: 'white',
    borderBottom: '1px solid grey',
  },
  checkoutButton: {
    backgroundColor: 'green',
    color: 'white',
    width: '95%',
    margin: 'auto',
    marginLeft: '4px',
    padding: '10px',
    marginTop: '10px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'darkgreen',
    },
    dialogBackdrop: {
      backdropFilter: 'blur(420px)', // Increase the pixel value for more blur
    },
  },
});


function ShoppingCart({startNewOrder, originalItems, cartItems, selectedCustomer, onEdit, onRemove, onCancelOrder, username}) {
  // Use the custom styles
  const classes = useStyles();

  useEffect(() => {
    console.log('ShoppingCart/cartItems--------: ', cartItems);
  }, [cartItems]);

  
  const [editCartItem, setEditCartItem] = useState(false);

  const [isEditing, setIsEditing] = useState('');
  const [editQuantity, setEditQuantity] = useState();

  const handleEditCartItem = (itemNo_, quantity) => {
    console.log('handleEditCartItem|Editing cart item:', itemNo_, quantity);
    setIsEditing(itemNo_);
    setEditQuantity(quantity);
  };
  const handleSaveEdit = (quantity, itemNo_) => {
    console.log('handleSaveEdit|Editing cart item:', itemNo_, quantity);
    onEdit(itemNo_, quantity);
    setIsEditing('');
  };
 
  useEffect(() => {
    console.log('ShoppingCart/selectedCustomer: ', selectedCustomer);
  }, [selectedCustomer]);

  // State for the dialog
  const [open, setOpen] = useState(false);
  // Handlers for opening and closing the dialog
  const handleOpen = () => {
    //document.body.classList.add('modal-open');
    setOpen(true);
  };  
  
  const [OrderData, setOrderData] = useState({
    CustId: selectedCustomer ? selectedCustomer.customerNo_ : '',
    needBy: '',
    beerLoadShipmentDate: '',
    duvelArrangesFreight: '',
    ShipToCode: '',
    customAddress: '',
    orderReference: '',
    attentionTo: '',
    SpendCodes: {
      "selectedPersonnel": "",
      "selectedDistributor": "",
      "selectedBillbackPercent": "",
      "selectedMarketing": false
    },
    items: cartItems,
    //cartItems: cartItems
  });

  const handleClose = () => {
    //document.body.classList.remove('modal-open');
    setOpen(false);
    console.log('ShoppingCart/handleClose - (before) OrderData.attentionTo: ', OrderData.attentionTo);
    console.log('ShoppingCart/handleClose - (before) OrderData.orderReference: ', OrderData.orderReference);
    OrderData.attentionTo = '';
    OrderData.orderReference = '';    
    OrderData.needBy = '';
    //OrderData.duvelArrangesFreight = '';
    OrderData.customAddress = '';
    console.log('ShoppingCart/handleClose - (after) OrderData: ', OrderData);
  };

  return (
    <>
      <div className={classes.header}>
        <div>
        <Typography variant="h6" style={{textAlign: 'center', marginTop: '16px'}}>Shopping Cart</Typography>
        </div>
        {/* <div style={{
                color: 'grey',
                textDecoration: 'none',
                marginLeft: '10px',
                fontSize: '10px'
              }}>
          (view summary)
        </div>
         */}
      </div>
      <Box className={classes.cart} style={{paddingTop: '10px'}}>
        {Object.entries(cartItems).map(([itemNo_, quantity]) => (
          <Box key={itemNo_} className={classes.item} style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", borderRadius: "inherit" }}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", color: 'black', width: "100%", height: "50%" }}>
              {/* <svg height="12px" width="12px" viewBox="0 0 100 100" style={{ color: "lightgrey", padding: '12px', borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' }}>
                <line x1="10" y1="10" x2="90" y2="90" stroke="lightgrey" strokeWidth="35" />
                <line x1="10" y1="90" x2="90" y2="10" stroke="lightgrey" strokeWidth="15" />
              </svg> */}
              <div style={{ marginLeft: "10px", textAlign: 'left', textOverflow: 'nowrap'}}>
                <div style={{ textOverflow: 'nowrap', overflow: 'hidden'}}>{originalItems.find(item => item.itemNo_ === itemNo_)?.description || ''}
                  
                </div>
                <div>
                  <div>Item #: {itemNo_}</div>
                  {isEditing != itemNo_ ? <span>Qty: {quantity}</span> : null}
                  <span style={{marginLeft: '8px'}}>
                  {isEditing === itemNo_ ? (
                      <>
                        <input
                          type="number"
                          value={editQuantity}
                          onChange={(e) => {
                            const value = parseInt(e.target.value);
                            setEditQuantity(value === 0 ? '' : value);
                          }}
                          onKeyDown={(e) => e.key === 'Enter' && handleSaveEdit(e.target.value, itemNo_)}
                          style={{ width: '50px', marginBottom: '4px' }}
                        />
                        <a href="#" style={{ marginLeft: '4px' }} onClick={(e) => { e.preventDefault(); handleSaveEdit(editQuantity, itemNo_); }}>Save</a>
                        <a href="#" onClick={(e) => { e.preventDefault(); setIsEditing(''); }} style={{ marginLeft: '8px' }}>Cancel</a>
                      </>
                    ) : (
                      <Link style={{ cursor: 'pointer' }} onClick={() => handleEditCartItem(itemNo_, quantity)}>edit</Link>
                    )}
                  </span>
                    {/* <Link style={{cursor: 'pointer'}} onClick={() => handleEditCartItem(itemNo_, quantity)}>edit</Link></span> */}
                    {isEditing  != itemNo_ ? <span style={{marginLeft: '16px'}}><Link style={{cursor: 'pointer'}} onClick={() => onRemove(itemNo_)}>remove</Link></span> : null}
                  </div>
              </div>

            </div>
          </Box>
        ))}
      </Box>
      <React.Fragment>
        {startNewOrder && (
          <>
            <Button className={classes.checkoutButton} onClick={handleOpen} disabled={Object.keys(cartItems).length === 0}>
              Review Order
            </Button>
            <Dialog fullWidth={true} maxWidth={"lg"} open={open} onClose={handleClose}
                    BackdropProps={{
                      classes: {
                        root: classes.dialogBackdrop,
                      },
                    }}>
              {/* <DialogTitle>Almost done...</DialogTitle> */}
              <DialogContent>
                {/* Content of the dialog */}
                {/* <ReviewOrder cartItems={cartItems} originalItems={originalItems} /> */}
                {selectedCustomer && selectedCustomer.customerNo_ ? (
                  <ReviewStepper cartItems={cartItems} originalItems={originalItems} orderData={OrderData}
                                 selectedCustomerNo={selectedCustomer.customerNo_}
                                 selectedCustomerName={selectedCustomer.customerName}
                                 isInternalCust={selectedCustomer.isInternal}
                                 setOrderData={setOrderData} username={username} />
                ) : null}
              </DialogContent>
            </Dialog>
            <Link style={{cursor: 'pointer', fontSize: '12px', color: 'grey', textAlign: 'center', display: 'block', marginTop: '8px'}} onClick={onCancelOrder}>Cancel Order</Link>
          </>
        )}
      </React.Fragment>
      
    </>
    
  );


}

export default ShoppingCart;
