import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    position: 'absolute',
    top: '135px',
    backgroundColor: 'white',
  },
});

export default function NavBar({theme, onChange}) {
  const classes = useStyles();
  const [value, setValue] = React.useState('DASHBOARD');

  const handleChange = (event, newValue) => {
    const selectedTabLabel = event.target.innerText;
    setValue(selectedTabLabel);
    //setValue(newValue);
    onChange(newValue);
  };

  return (
    <Paper className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="Dashboard" value="DASHBOARD" />
        <Tab label="POS" value="POS" />
        <Tab label="ORDERS" value="ORDERS" />
        <Tab label="PREORDERS" value="PREORDERS" />
        <Tab label="REPORTS" value="REPORTS" />
        <Tab label="FAQ" value="FAQ" />
        <Tab label="CONTACT" value="CONTACT" />
      </Tabs>
    </Paper>
  );
}
