import React, { useState, useEffect } from 'react';
import { Typography, Select, MenuItem, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete, TextField } from '@material-ui/lab';


const useStyles = makeStyles({
  addressPaper: {
    border: 'none',
    boxShadow: 'none',
    position: 'relative',
    '&:hover': {
      color: 'black',
      boxShadow: '1px 1px 1px 1px rgba(0,0,0,0.1)',
      '& $addressPaperTitle': {
        color: 'black',
      },
    }
  },
  addressPaperTitle: {
    color: 'black',
    marginTop: '16px',
    marginBottom: '16px',
    fontWeight: '100',
    fontSize: '18px',
    '&:hover': {
      color: '#b3b2b2',
      //#b3b2b2
    }
  },
  addressEditButton: {
    display: 'inline-block',
    color: 'lightgrey',
    fontSize: '12px',
    cursor: 'pointer',
    marginLeft: '12px',
    textAlign: 'right',
    position: 'absolute',
    right: '0',
    '&:hover': {
      color: 'blue',
      fontWeight: '700',
      textDecoration: 'underline',
    }
  }
});

const SpendCodes = ({ personnelCodes, distributorCodes, billbackPercents, showNext, onSpendingCodesUpdate, onSelectedSpendingType, isInternalCust, username }) => {
  const classes = useStyles();
  //const [selectedOption, setSelectedOption] = useState('');
  // const [dropdownValue, setDropdownValue] = useState({
  //   'GL Account': '',
  //   'Personnel Code': '',
  //   'Brand Code': '',
  //   'Distributor Code': '',
  //   'Billback %': ''
  // });

  // const options = {
  //   Personal: ['GL Account', 'Personnel Code', 'Brand Code'],
  //   Distributor: ['GL Account', 'Distributor Code', 'Brand Code', 'Billback %'],
  //   Marketing: ['GL Account', 'Brand Code']
  // };

  const simpleOptions = ['Personal', 'Distributor', 'Marketing'];
  const [selectedOption, setSelectedOption] = useState('');
  // const [personnelCodes, setPersonnelCodes] = useState([]);
  // const [distributorCodes, setDistributorCodes] = useState([]);
  // const [billbackPercents, setBillbackPercents] = useState([]);

  // useEffect(() => {
  //   // Fetch personnel codes when selectedOption is 'Personal'
  //   if (selectedOption === 'Personal') {
  //     fetchPersonnelCodes();
  //   }
  //   // Fetch distributor codes when selectedOption is 'Distributor'
  //   if (selectedOption === 'Distributor') {
  //     fetchDistributorCodes();
  //     fetchBillbackPercents();

  //   }
  // }, [selectedOption]);



  // const fetchPersonnelCodes = async () => {
  //   try {
  //     //check if the PersonenlCodes is already fetched
  //     if (personnelCodes.length > 0) {
  //       return;
  //     }
  //     const response = await fetch('https://posapi.duvelusa.com/Orders/GetPersonnelCodes');
  //     const data = await response.json();
  //     setPersonnelCodes(data);
  //   } catch (error) {
  //     console.error('Error fetching personnel codes:', error);
  //   }
  // };

  // const fetchDistributorCodes = async () => {
  //   try {
  //     //check if the DistributorCodes is already fetched
  //     if (distributorCodes.length > 0) {
  //       return;
  //     }
  //     const response = await fetch('https://posapi.duvelusa.com/Orders/GetDistributorCodes');
  //     const data = await response.json();
  //     setDistributorCodes(data);
  //   } catch (error) {
  //     console.error('Error fetching distributor codes:', error);
  //   }
  // };

  // const fetchBillbackPercents = async () => {
  //   try {
  //     const response = await fetch(`https://posapi.duvelusa.com/Orders/GetBillbackPercents`);
  //     const data = await response.json();
  //     setBillbackPercents(data);
  //   } catch (error) {
  //     console.error('Error fetching billback percents:', error);
  //   }
  // };

  const [selectedPersonnel, setSelectedPersonnel] = useState('');
  const [selectedDistributor, setSelectedDistributor] = useState('');
  const [selectedBillbackPercent, setSelectedBillbackPercent] = useState('');
  const [selectedMarketing, setSelectedMarketing] = useState(false);
  const [allowedMarketing, setAllowedMarketing] = useState(false);

  useEffect(() => {
    const checkMarketingAllowed = async () => {
      try {
        const response = await fetch(`https://posapi.duvelusa.com/Orders/${username}/IsMarketingAllowed`);
        const data = await response.text(); // Assuming the response is plain text
        setAllowedMarketing(data === 'true');
      } catch (error) {
        console.error('Error checking if marketing is allowed:', error);
        setAllowedMarketing(false);
      }
    };

    checkMarketingAllowed();
  }, []);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
      //if personnel code is selected: clearout the distributor code
      if (selectedOption === 'Personal') {
        setSelectedDistributor('');
        setSelectedBillbackPercent('');
        setSelectedMarketing(false);
        showNext(false);
      };

           
      //if distributor code is selected: clearout the personnel code
      if (selectedOption === 'Distributor') {
        setSelectedPersonnel('');
        setSelectedMarketing(false);
        showNext(false);
      };

      //if marketing code is selected: clearout the personnel code and distributor code
      if (selectedOption === 'Marketing') {
        setSelectedPersonnel('');
        setSelectedDistributor('');
        setSelectedBillbackPercent('');
        showNext(false);
      };

      onSelectedSpendingType(event.target.value);
  };


  const handlePersonnelChange = (event) => {
    setSelectedPersonnel(event.target.value);
    showNext(true);
  };

  const handleDistributorChange = (event) => {
    setSelectedDistributor(event.target.value);
    if (selectedBillbackPercent !== '') {
      showNext(true);
    }
  };

  const handleBillbackPercentChange = (event) => {
    setSelectedBillbackPercent(event.target.value);
    //this needs to check if it is filled out and the distibutor var is filled out
    if (selectedDistributor !== '') {
      showNext(true);
    }
  };

  const handleMarketingClick = (event) => {
    setSelectedMarketing(event.target.checked);
    setSelectedPersonnel('');
    setSelectedDistributor('');
    setSelectedBillbackPercent('');
    showNext(event.target.checked);
  };

   useEffect(() => {
    const spendingCodes = {
      selectedPersonnel,
      selectedDistributor,
      selectedBillbackPercent,
      selectedMarketing,
    };
    onSpendingCodesUpdate(spendingCodes);
  }, [selectedPersonnel, selectedDistributor, selectedBillbackPercent, selectedMarketing]);

  return (
    <div style={{height: '64px', padding: '10px', height: '256px'}}>
      {/* <Typography className={classes.addressPaperTitle} variant='h6' style={{}}>Spending Codes:</Typography> */}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '32px', marginBottom: '56px' }}>
        {/* {['Personal', 'Distributor', 'Marketing'].map(option => (
          <label key={option}>
            <input type="radio" value={option} checked={selectedOption === option} onChange={() => setSelectedOption(option)} />
            {option}
          </label>
        ))} */}
        <span style={{minWidth: '200px'}}><Typography variant='h5'>Spend type: </Typography></span>
        <Select style={{minWidth: '300px'}} value={selectedOption} onChange={handleOptionChange} displayEmpty renderValue={(value) => (value ? value : 'Select type of spending')}>
          <MenuItem value="" disabled> </MenuItem>
          <MenuItem value="Personal">Personal</MenuItem>
          {!isInternalCust && (
            <MenuItem value="Distributor">Distributor</MenuItem>
          )}
          {allowedMarketing && (
            <MenuItem value="Marketing">Marketing</MenuItem>
          )}
        </Select>

      </div>
      
      {selectedOption === 'Personal' && (
        <>
          <label style={{marginLeft: '160px', minWidth: '300px'}}>Personnel Code:</label>
          <Select style={{marginLeft: '200px', minWidth: '350px'}} value={selectedPersonnel} onChange={handlePersonnelChange} placeholder='select personnel code'>
            <option value="" disabled selected hidden>
              Select a personnel code...
            </option>
            {personnelCodes.map((pc) => (
              <option key={pc.code} value={pc.code + '|' + pc.name}>
                {pc.name}
              </option>
            ))}
          </Select>
        </>
      )}

      {selectedOption === 'Distributor' && (
        <>
          <label style={{marginLeft: '160px', minWidth: '300px'}}>Distributor Code:</label>
          <Select
            style={{
              marginLeft: '200px',
              minWidth: '350px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              padding: '8px',
              cursor: 'pointer'
            }}
            value={selectedDistributor}
            onChange={handleDistributorChange}
            placeholder='select distributor code'
          >
            <option value="" disabled selected hidden>
              Select a distributor code...
            </option>
            {distributorCodes.map((dc) => (
              <option style={{
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: 'green',
                      },
                    }} key={dc.code} value={dc.code + '|' + dc.name}>
                      {dc.name}
              </option>
            ))}
          </Select>
          <label style={{marginLeft: '160px', minWidth: '300px', marginTop: '24px'}}>Billback %:</label>
          <Select style={{marginLeft: '200px',
              minWidth: '350px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              padding: '8px',
              cursor: 'pointer'}} value={selectedBillbackPercent} onChange={handleBillbackPercentChange} placeholder='select billback percent'>
            <option value="" disabled selected hidden>
              Select a billback percent...
            </option>
            {billbackPercents.map((bp) => (
              <option style={{
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: '#f0f0f0',
                      },
                    }} key={bp.code} value={bp.code + '|' + bp.name}>
                {bp.name}
              </option>
            ))}
          </Select>
        </>
      )}

      {selectedOption === 'Marketing' && (
        <Typography style={{marginLeft: '150px'}} variant="body1">
          Marketing <Checkbox checked={selectedMarketing} onClick={handleMarketingClick} />
        </Typography>
      )}

{/* 
      {selectedOption && options[selectedOption].map(option => (
        <div key={option}>
          <label>{option}</label>
          <select style={{marginLeft: '16px'}} value={dropdownValue[option]} onChange={e => handleSelectChange(option, e)}>
            {Array.from({length: 5}, (_, i) => `${option} ${i+1}`).map(dummyOption => (
              <option key={dummyOption} value={dummyOption}>{dummyOption}</option>
            ))}
          </select>
        </div>
      ))} */}
    </div>
  );
};

export default SpendCodes;
