import './App.css';
import { useState, useEffect } from 'react';
import NavBar from './components/NavBar';
import POS_MainPage from './components/POS/POS_MainPage';
import { environment } from './environment.js';
import AuthContext from './authentication/auth_context';
import ThemeSwitch from './components/ThemeSwitch.js';
import x from './imgs/bg-usa-short.8b7fb7bd591d4bc846c7.png';
import { MsalProvider, useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import msalInstance from './authentication/authConfig.js';
import LoginButton from './authentication/LoginButton.js';

function App() {
  /* --------------------------------  AUTHENTICATION STUFF --------------------------------  */
  const request = {
    loginHint: "name@example.com",
    scopes: ["User.Read"]
  }

  const { accounts, instance } = useMsal();
  const [username, setUsername] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);

  const handleLogin = (username) => {
    setUsername(username);
  };


  const handleLogout = () => {
    try {
      // Remove the msal.account.keys entry from session storage
      sessionStorage.removeItem('msal.account.keys');

      // Optionally, you can also clear other MSAL-related entries from session storage
      sessionStorage.removeItem('msal.idtoken');
      sessionStorage.removeItem('msal.client.info');
      
      window.location.reload();
    }
    catch (e) {
      console.error(e);
    }
    
  };

  useEffect(() => {
    //
    //first check for new version
    //and reload if necessary
    //
    fetch('https://posapi.duvelusa.com/Orders/GetCurrentVersion')
    .then(response => response.json())
    .then(data => {
      const latestVersion = data.version;
      const currentVersion = localStorage.getItem('appVersion');
  
      if (!currentVersion || latestVersion > currentVersion) {
        //setNewVersionAvailable(true);
        localStorage.setItem('appVersion', latestVersion);
        window.location.reload(true);
      }
    })
    .catch(error => {
      console.error('Error:', error);
    });
    //
    //then do the auth stuff
    //
    const authConfig = environment.authConfig;
    //console.log('authConfig: ', authConfig);
    const myAccounts = msalInstance.getAllAccounts();
    console.log("Accounts from instance: ", myAccounts);
    if (myAccounts && myAccounts[0]) {
      console.log("idTokenClaims: ", myAccounts[0].idTokenClaims);
      console.log("account groups: ", myAccounts[0].idTokenClaims.groups);
    }   
    if (myAccounts && myAccounts[0]) {
      //console.log("username: ", myAccounts[0].username);
      setUsername(myAccounts[0].username);
  
      // Check if the array contains the value
      if (myAccounts && myAccounts[0]) {
        if (myAccounts[0].idTokenClaims.groups.includes('189a0077-0e74-4513-8f4b-f28ed1dd20e4')) {
          setIsAdmin(true);
        }  
      }
    }
  }, []);
  // useEffect(() => {
  //   const authConfig = environment.authConfig;
  //   console.log('authConfig: ', authConfig);
  //   //AuthContext.initialize(authConfig);
  // }, []);

  /* --------------------------------  END AUTHENTICATION STUFF --------------------------------  */

  //const [selectedNav, setSelectedNav] = useState('POS');
  const [theme, setTheme] = useState('light');

  function handleThemeChange(newValue) {
    console.log("handleThemeChange:", newValue);
    setTheme(newValue);
  }

  return (
    <MsalProvider instance={msalInstance}>
      <div className="App" style={{backgroundColor: theme === 'light' ? '#e3e6e6' : '#333333', paddingTop: '0'}}>
        {/* <header className="App-header">
          <img src={x} alt="Portal 2024 Logo" />
          <NavBar theme={theme} onChange={handleNavChange}/>
        </header> */}
        <AuthenticatedTemplate>
          <div className="App-body">
            <POS_MainPage theme={theme} username={username} isAdmin={false} onLogOut={handleLogout} />
          </div>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <LoginButton onLogin={handleLogin} />
        </UnauthenticatedTemplate>
      </div>
    </MsalProvider>
  );
}

export default App;
