import { PublicClientApplication, LogLevel  } from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: '3b3c7b93-6f38-436f-9d95-d8f4e9899e6f',
    authority: 'https://login.microsoftonline.com/ca9030b5-20c6-4842-a755-c6128d0e5861', // replace with your Azure AD tenant ID


    // PRODUCTION / LIVE
    redirectUri: 'https://pos.duvelusa.com',

    // DEV ENVIROMENT
    //redirectUri: 'http://localhost:3000',
    
    
    
    navigateToLoginRequestUrl: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        console.log(message);
      },
      piiLoggingEnabled: false,
      logLevel: LogLevel.Error,
    },
  },
  authOptions: {
    protocolType: "OpenIdConnect", // Set this to disable PKCE
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

export default msalInstance;