// import { useEffect, useState } from 'react';
// import Grid from '@material-ui/core/Grid';
// import Item from './Item';
// import items from '../DummyData/items.json';

// // Define a parent component that loops over the JSON object
// export default function ItemList({ brandFilter, itemTypeFilter }) {
//   // Define a state variable to store the JSON object
//   const [items_list, setItems_list] = useState({});

//   useEffect(() => {
//     setItems_list(items);
//   }, []);

//   useEffect(() => {
//     // Define a function that filters the items by brand and item type
//     const filterItems = (brand, itemType) => {
//       // Return true if the item matches both criteria
//       return item.brand === brand && item.itemNo_.startsWith(itemType);
//     };

//     // Create a new array with only the filtered items
//     const filteredItems = items.filter((item) => {
//       return filterItems(brandFilter, itemTypeFilter);
//     });

//     // Update the state variable with the new array
//     setItems_list(filteredItems);
//   }, [brandFilter, itemTypeFilter]); // Pass an array of dependencies

//   // Return the JSX markup
//   return (
//     // Use the Grid component as a container for the items
//     <Grid container spacing={2}>
//       {items_list ? Object.keys(items_list).map((brand) => {
//         return items[brand].map((item) => {
//           return (
//             <Grid item xs={12}>
//               <Item
//                 itemNo_={item.itemNo_}
//                 description={item.description}
//                 size={item.size}
//                 price={item.price}
//                 unit={item.unit}
//                 case_pack={item.case_pack}
//                 brand={item.brand}
//               />
//             </Grid>
//           );
//         });
//       }) : null}
//     </Grid>
//   );
// }

  // useEffect(() => {    
  //   console.log('ItemList/brandFilter [brandFilter, itemTypeFilter]: ', brandFilter);
  //   console.log('ItemList/itemTypeFilter [brandFilter, itemTypeFilter]: ', itemTypeFilter);
  //   const filterItems = (item) => {
  //     // If no filters are selected, return true
  //     if (brandFilter.length === 0 && itemTypeFilter.length === 0) {
  //       return true;
  //     }
  //     // If only brandFilter is selected
  //     if (brandFilter.length > 0 && itemTypeFilter.length === 0) {
  //       return brandFilter.includes(item.brand);
  //     }
  //     // If only itemTypeFilter is selected
  //     if (brandFilter.length === 0 && itemTypeFilter.length > 0) {
  //       return itemTypeFilter.includes(item.category);
  //     }
  //     // If both filters are selected
  //     return brandFilter.includes(item.brand) && itemTypeFilter.includes(item.category);
  //   };

  //   // Apply the filters to the original items
  //   const filteredItems = originalItems.filter(filterItems);

  //   // Update the state with the filtered items
  //   setItems_list(filteredItems);
  // }, [brandFilter, itemTypeFilter]);


import React, { useEffect, useState, useRef, lazy, Suspense } from 'react';
import { Paper, Typography, TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
//mport Item from './Item';
const Item = lazy(() => import('./Item'));
//import items from '../DummyData/items.json';

export default function ItemList({ originalItems, brandFilter, itemTypeFilter, onCartUpdate, shoppingCart }) {
  //const [originalItems, setOriginalItems] = useState(Object.values(items).flat());
  const [search, setSearch] = useState('');
  const [items_list, setItems_list] = useState(originalItems);
  const [cart, setCart] = useState({});

  const searchRef = useRef();

  useEffect(() => {
    searchRef.current.focus();
  }, []);

  // useEffect(() => {
  //   console.log('ItemList/shoppingCart: ', shoppingCart);
  // }, [shoppingCart]);

  useEffect(() => {
    const filterItems = (item) => {
      // If no filters are selected, return true
      if (brandFilter.length === 0 && itemTypeFilter.length === 0 && search.length === 0) {
        return true;
      }
      // If only brandFilter is selected
      if (brandFilter.length > 0 && itemTypeFilter.length === 0 && search.length === 0) {
        return brandFilter.includes(item.brand);
      }
      // If only itemTypeFilter is selected
      if (brandFilter.length === 0 && itemTypeFilter.length > 0 && search.length === 0) {
        return itemTypeFilter.includes(item.category);
      }
      // If both filters are selected
      if (brandFilter.length > 0 && itemTypeFilter.length > 0 && search.length === 0) {
        return brandFilter.includes(item.brand) && itemTypeFilter.includes(item.category);
      }
      // If search is used
      if (search.length > 0) {
        // console.log('ItemList/search: ', search);
        // console.log('ItemList/item.description: ', item.description);
        if (item && typeof item.description === 'string') {
          return item.description.toLowerCase().includes(search.toLowerCase());
        }
      }
    };

    // Apply the filters to the original items
    const filteredItems = originalItems.filter(filterItems);

    // Update the state with the filtered items
    setItems_list(filteredItems);
  }, [brandFilter, itemTypeFilter, search]);

  function handleAddToCart(itemNo_, quantity, description) {
    
    // If quantity is not over 0, don't do anything else in this function
    if (quantity <= 0) {
      return;
    }
    console.log('ItemList/handleAddToCart - before update cart: ', cart);
    console.log('ItemList/handleAddToCart/itemNo_ & quantity & description: ', itemNo_ + ' ' + quantity + ' ' + description);
    onCartUpdate(itemNo_, quantity, description);
    // setCart(prevCart => {
    //   return {...prevCart, [itemNo_]: (prevCart[itemNo_] || 0) + quantity};
    // });
    //onCartUpdate(cart);
    console.log('ItemList/handleAddToCar - after update cart: ', cart);
    console.log('ItemList/handleAddToCart/cart: ', cart);
  }

  useEffect(() => {
    console.log('ItemList/cart: ', cart);
  }, [cart]);
  // Use another useEffect to call the onCartUpdate function whenever the cart changes
  // useEffect(() => {
  //   onCartUpdate(cart);
  // }, [cart, onCartUpdate]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={10} style={{margin: 'auto'}}>
        {/* <div style={{textAlign: 'left'}}>Displaying {items_list.length} items</div> */}
        <div style={{textAlign: 'left'}}> Displaying {items_list.length} items
          {search.length > 0 && (
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setSearch('');
                searchRef.current.value = '';
                }}
              style={{
                color: 'grey',
                textDecoration: 'none',
                fontStyle: 'italic',
                marginLeft: '10px',
              }}>(click to clear search)
            </a>)}
        </div>
        <Paper style={{border: "1px solid black", borderRadius: '10px', padding: '8px', margin: 'auto',
                       borderRadius: '10px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'}}>
          {/* <Typography variant="h6" style={{textAlign: 'left'}}>Items</Typography> */}
          
          <TextField
            label="Press enter to search"
            //value={search}
            //onChange={e => setSearch(e.target.value)}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                setSearch(e.target.value);
              }
            }}
            style={{width: '100%', fontSize: '20px', borderRadius: '10px'}}
            autoFocus
            inputRef={searchRef}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Paper>
      </Grid>
      <Grid container> 
       {/* style={{minHeight: '1600px'}}> */}
       <Suspense fallback={<div>Loading...</div>}>
          {items_list.map((item) => (
            //console.log('ItemList/item + ' + item.itemNo_+ ': ', shoppingCart.hasOwnProperty(item.itemNo_)),
            <Grid item xs={12} key={item.itemNo_}>
              <Item onAddToCart={(itemNo_, quantity, description) => { handleAddToCart(itemNo_, quantity, item.description)}}
                itemNo_={item.itemNo_}
                description={item.description}
                size={item.size}
                price={item.price}
                uom={item.uom}
                case_pack={item.case_pack}
                brand={item.brand}
                category={item.category}
                imageData={item.imageData}
                stock={item.stock}
                //shoppingCart={shoppingCart}
                isInCart={shoppingCart.hasOwnProperty(item.itemNo_)}
                //isInCart={shoppingCart.hasOwnProperty(item.itemNo_)}
              />
            </Grid>
          ))}
        </Suspense>
      </Grid>
    </Grid>
  );
}
