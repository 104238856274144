import { Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';

const ShippingInfoComponent = ({ customerId, showGoogleLink, customAddress }) => {
  const [shippingInfo, setShippingInfo] = useState(null);

  useEffect(() => {
    // Call the endpoint when the component is rendered
    fetch(`https://posapi.duvelusa.com/Orders/GetShippingAddress/${customerId}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        // Store the response data in the state variable
        setShippingInfo(data);
      })
      .catch(error => {
        console.error('There was an error fetching the shipping address!', error);
      });
  }, []); // The empty array ensures this effect runs only once after the initial render

  return (
    <div>
      {customAddress && (
        <div style={{marginTop: '12px'}}>
          <div>{customAddress}</div>
          <div style={{marginTop: '24px', marginBottom: '4px'}}><label style={{color: 'red'}}>Verify custom address with your contact.</label></div>
          
          {showGoogleLink && (<a style={{marginTop: '4px', color: '#1976d2'}} href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(customAddress)}`} target="_blank" rel="noopener noreferrer">(Open in Maps)</a>)}
        </div>
      )}


      {shippingInfo && !customAddress ? (
        // Display the shipToAddress property from the ShippingInfo state variable
        <>
          <div style={{marginTop: '12px'}}>{shippingInfo.shipToAddress}</div>
          {showGoogleLink && (<a href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(shippingInfo.shipToAddress)}`} target="_blank" rel="noopener noreferrer" style={{color: '#1976d2', marginTop: '8px'}}>(Open in Maps)</a>)}
        </>
      ) : null}
      {/* (
        <p>Loading shipping information...</p>
      )
      } */}
    </div>
  );
};

export default ShippingInfoComponent;
