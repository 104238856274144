import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

const ShippingBeerLoads = ({customerId, onSelectedBeerLoad}) => {
  const [beerLoads, setBeerLoads] = useState([]);

  useEffect(() => {
    const fetchBeerLoads = async () => {
      try {
        const response = await fetch(`https://posapi.duvelusa.com/Orders/GetBeerLoads_ByCustomerId/${customerId}`);
        if (!response.ok) {
          throw new Error('Error fetching beer loads');
        }
        const data = await response.json();
        setBeerLoads(data);
      } catch (error) {
        console.error('Error fetching beer loads:', error);
      }
    };

    fetchBeerLoads();
  }, []);

  const [selectedBeerLoadNo, setSelectedBeerLoadNo] = useState(null);
  const [selectedShipmentDate, setSelectedShipmentDate] = useState(null);
  
  const handleSelectedBeerLoad = (salesOrderNo, shipmentDate) => {
    setSelectedBeerLoadNo(salesOrderNo);
    setSelectedShipmentDate(shipmentDate);
    onSelectedBeerLoad(salesOrderNo, shipmentDate);
  };

  return (
    <>
      <div style={{marginBottom: '8px', borderRadius: '4px', padding: '4px'}}>
        <Typography>Selected Shipment date: {selectedShipmentDate}</Typography></div>
      <div style={{ maxHeight: '220px', overflowY: 'auto', borderTop: '1px solid grey', borderRight: '1px solid grey', borderBottom: '1px solid grey', paddingTop: '1px' }}>
        {beerLoads.map((load, index) => (
          <div
            key={index}
            onClick={() => handleSelectedBeerLoad(load.salesOrderNo,new Date(load.shipmentDate).toLocaleDateString())}
            style={{
              borderRadius: '10px',
              border:
                selectedBeerLoadNo === load.salesOrderNo
                  ? '2px solid red'
                  : '1px solid black',
              padding: '10px',
              marginBottom: '10px',
              fontSize: '16px',
              userSelect: 'none',
              cursor: 'pointer',
            }}
          >
            <div>{load.salesOrderNo}</div>
            <div>
              <span>Shipment Date: </span>
              <span>{new Date(load.shipmentDate).toLocaleDateString()}</span>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ShippingBeerLoads;