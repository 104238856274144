import React, { useState, useEffect } from 'react';
import { Grid, Checkbox, FormControlLabel, TextField, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Admin_ItemsList from './Admin_ItemsList';

const useStyles = makeStyles((theme) => ({
  checkboxGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: theme.spacing(2),
  },
  searchTextbox: {
    width: 300,
  },
}));

const Admin = () => {
  const classes = useStyles();
  const [searchQuery, setSearchQuery] = useState('');
  const [showZeroNoPrice, setShowZeroNoPrice] = useState(false);
  const [showHiddenOnly, setShowHiddenOnly] = useState(false);
  //const [showNonHiddenOnly, setShowNonHiddenOnly] = useState(false);
  const [showNoImageOnly, setShowNoImageOnly] = useState(false);

  const [items, setItems] = useState([]);

  const fetchItems = () => {
    //alert('4')
    fetch('https://posapi.duvelusa.com/Orders/getAdminItems')
      .then((response) => response.json())
      .then((data) => {
        setItems(data);
      });
  };

  
  useEffect(() => {
    // fetch('https://posapi.duvelusa.com/Orders/getAdminItems')
    //   .then((response) => response.json())
    //   .then((data) => {
    //     setItems(data);
    //   });
    fetchItems();
  }, []);

  // useEffect(() => {
  //   fetch('https://posapi.duvelusa.com/Orders/getAdminItems')
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setItems(data);
  //     });
  // }, []);

  const filteredItems = items.filter((item) => {
    const matchesSearchQuery = item.description.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesZeroNoPrice = !showZeroNoPrice || item.price === 0 || item.price === null || item.price === undefined;
    const matchesHiddenOnly = !showHiddenOnly || item.blocked;
    //const matchesNonHiddenOnly = !showNonHiddenOnly || !item.hidden;
    const matchesNoImageOnly = !showNoImageOnly || !item.imagePath;

    return matchesSearchQuery && matchesZeroNoPrice && matchesHiddenOnly && matchesNoImageOnly;
    //matchesNonHiddenOnly && ;
  });

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleZeroNoPriceChange = (event) => {
    setShowZeroNoPrice(event.target.checked);
  };

  const handleHiddenOnlyChange = (event) => {
    setShowHiddenOnly(event.target.checked);
  };

  // const handleNonHiddenOnlyChange = (event) => {
  //   setShowNonHiddenOnly(event.target.checked);
  // };

  const handleNoImageOnlyChange = (event) => {
    setShowNoImageOnly(event.target.checked);
  };

  return (
    <div style={{margin: 'auto', width: '80%'}}>
      <Paper>
        <Grid container alignItems="center">
          <Grid item>
            <TextField
              className={classes.searchTextbox}
              label="Search by description"
              value={searchQuery}
              onChange={handleSearchChange}
              variant="outlined"
              width="100%"
            />
          </Grid>
          <Grid item className={classes.checkboxGroup}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showZeroNoPrice}
                  onChange={handleZeroNoPriceChange}
                  color="primary"
                />
              }
              label="No Price"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={showHiddenOnly}
                  onChange={handleHiddenOnlyChange}
                  color="primary"
                />
              }
              label="Hidden"
            />
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={showNonHiddenOnly}
                  onChange={handleNonHiddenOnlyChange}
                  color="primary"
                />
              }
              label="Non-Hidden"
            /> */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={showNoImageOnly}
                  onChange={handleNoImageOnlyChange}
                  color="primary"
                />
              }
              label="No Image"
            />
          </Grid>
        </Grid>
      </Paper>
      <div>Displaying {filteredItems.length} of {items.length}</div>
      <Admin_ItemsList originalItems={filteredItems} refreshItems={fetchItems} />
    </div>
  );
};

export default Admin;