import React, { useState, useEffect } from 'react';
import { Checkbox, FormControlLabel, Snackbar, Button } from '@material-ui/core';

function CCEmails({ }) {// ccEmails, setccEmails }) {
  //const [emails, setEmails] = useState([]);
  const [emails, setEmails] = useState([
    { email: 'john.doe@example.com' },
    { email: 'jane.smith@example.com' },
    { email: 'mike.johnson@example.com' }
  ]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [newEmail, setNewEmail] = useState('');

  // useEffect(() => {
  //   // Replace 'your-api-endpoint' with the actual endpoint
  //   fetch('your-api-endpoint')
  //     .then(response => response.json())
  //     .then(data => {
  //       setEmails(data.map(item => ({ ...item, checked: false })));
  //     });
  // }, []);

  const handleToggle = email => {
    const updatedEmails = emails.map(item =>
      item.email === email ? { ...item, checked: !item.checked } : item
    );
    setEmails(updatedEmails);
    //setccEmails(updatedEmails.filter(item => item.checked).map(item => item.email));
  };

  const handleAddEmail = () => {
    if (newEmail) {
      setEmails([...emails, { email: newEmail, checked: true }]);
      //setccEmails([...ccEmails, newEmail]);
      setNewEmail('');
      setSnackbarOpen(false);
    }
  };

  return (
    <div>
      {emails.map((item, index) => (
        <FormControlLabel
          key={index}
          control={
            <Checkbox
              checked={item.checked}
              onChange={() => handleToggle(item.email)}
              style={{ color: 'black' }}
            />
          }
          label={item.email}
        />
      ))}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={snackbarOpen}
        message={
          <div>
            Add new CC Email:
            <input value={newEmail} onChange={e => setNewEmail(e.target.value)} />
            <Button color="primary" onClick={handleAddEmail}>
              Add
            </Button>
          </div>
        }
        action={
          <Button color="secondary" size="small" onClick={() => setSnackbarOpen(false)}>
            Cancel
          </Button>
        }
      />
      <Button color="primary" onClick={() => setSnackbarOpen(true)}>
        Add New CC Email
      </Button>
    </div>
  );
}

export default CCEmails;
