// // FilterGroup component
// import { useState } from 'react';
// import { makeStyles } from '@material-ui/core/styles';

// // define the styles object using makeStyles
// const useStyles = makeStyles({
//   root: {
//     border: "1px solid black",
//     // margin: "20px",
//     borderRadius: "10px",
//     boxShadow: "0px 0px 8px -2px tan",
//     textAlign: "left",
//     paddingBottom: "24px",
//     backgroundColor: "white",
//     marginLeft: '12px',
//   },
//   header: {
//     borderBottom: "1px solid black",
//     marginBottom: "16px",
//   },
//   title: {
//     fontSize: "18px",
//     width: "100%",
//     marginLeft: "32px",
//   },
//   item: {
//     paddingLeft: "5%",
//     paddingBottom: "4px",
//     paddingTop: "4px",
//     width: "60%",
//     margin: 'auto',
//     cursor: "pointer",
//     '&:hover': {
//       // backgroundColor: '#00eaff3b'
//       backgroundColor: 'lightgreen'
//     },
//     borderRadius: '64px'
//   },
//   label: {
//     fontSize: "18px",
//     fontWeight: "bold",
//     cursor: "pointer"
//   },
//   input: {
//     height: "20px",
//     width: "20px",
//     borderRadius: "5px",
//     border: "2px solid",
//     marginRight: "14px",
//     verticalAlign: "top",
//     cursor: "pointer"
//   },
// });



// export default function FilterGroup(props) {
//   // props: title, filterByItems
//   // filterByItems: an array of items to filter by
//   // handleFilterChange: a function to handle the change of filter selection

//   const classes = useStyles(); // use the styles object
//   const [selectedItems, setSelectedItems] = useState([]);

//   const handleFilterChange = (event, selectedItems, setSelectedItems) => {

//     const { value } = event.target;

//     props.setSelectedItems((prevState) =>
//       prevState.includes(value.toUpperCase())
//         ? prevState.filter((item) => item !== value.toUpperCase())
//         : [...prevState, value.toUpperCase()]
//     );
//     //log the new value
//     console.log("FilterGroup/newValue: ", value.toUpperCase());
//   };
  
//   return (
//     <div className={classes.root}>
//       <div className={classes.header}>
//         <h2 className={classes.title}>{props.title}</h2>
//       </div>
//       {props.filterByItems.map((item, index) => (
//         <div key={index} className={classes.item}>
//           <label className={classes.label}>
//             <input
//               type="checkbox"
//               value={item}
//               onChange={handleFilterChange}
//               className={classes.input}
//             />
//             {item}
//           </label>
//         </div>
//       ))}
//     </div>
//   );
// }

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import '@fontsource/roboto/700.css';

const useStyles = makeStyles({
  root: {
    border: "1px solid black",
    borderRadius: "10px",
    boxShadow: "0px 0px 8px -2px tan",
    textAlign: "left",
    paddingBottom: "24px",
    backgroundColor: "white",
    marginLeft: '12px',
    color: '#5D4037',
    userSelect: 'none',
  },
  header: {
    borderBottom: "1px solid black",
    marginBottom: "16px",
    backgroundColor: '#eb2600',
    color: 'white',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    boxShadow: '0 0 3px 0px black',
  },
  title: {
    fontSize: "18px",
    width: "100%",
    marginLeft: "32px",
  },
  item: {
    '&:hover': {
      backgroundColor: '#eb26003b'
    },
    borderRadius: '64px',
    paddingLeft: "5%",
    paddingBottom: "4px",
    paddingTop: "4px",
    width: "90%",
    margin: 'auto',
    cursor: "pointer"  
  },
  label: {
    fontSize: "18px",
    fontWeight: "bold",
    cursor: "pointer"
  },
  input: {
    height: "20px",
    width: "20px",
    borderRadius: "5px",
    border: "2px solid",
    marginRight: "14px",
    verticalAlign: "top",
    cursor: "pointer"
  },
});

export default function FilterGroup({ title, filterByItems, setSelectedItems }) {
  const classes = useStyles();

  const handleFilterChange = (event) => {
    console.log('FilterGroup/event: ', event);
    console.log('FilterGroup/event.target.value: ', event.target.value);
    const { value } = event.target;
    setSelectedItems(value.toUpperCase());
    // setSelectedItems((prevState) =>
    //   prevState.includes(value.toUpperCase())
    //     ? prevState.filter((item) => item !== value.toUpperCase())
    //     : [...prevState, value.toUpperCase()]
    // );
    console.log("FilterGroup/newValue: ", value.toUpperCase());
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h6" className={classes.title}>{title}</Typography>
        {/* <h2 className={classes.title}>{title}</h2> */}
      </div>
      {filterByItems.map((item, index) => (
        <div key={index} className={classes.item}>
          <Typography variant="body1" className={classes.label}>
            <FormControlLabel label={item} control={<Checkbox value={item} onChange={(event) => handleFilterChange(event)} />}/>
          </Typography>
        </div>
      ))}
    </div>
  );
}



{/* <label className={classes.label}> */}
  {/* <input
    type="checkbox"
    value={item}
    onChange={(event) => handleFilterChange(event)}
    className={classes.input}
  />
  <label>{item}</label> */}
{/* </label> */}